#voices .column {
  flex: 1 0 auto;
}

#voices .column:nth-child(1) {
  width: 90px;
}

#voices .column:nth-child(2) {
  width: 115px;
}

#voices .column:nth-child(3) {
  width: 125px;
}

#voices .column:nth-child(4) {
  width: 100px;
}

#voices .column:nth-child(5) {
  width: 85px;
}

#voices .column:nth-child(6) {
  width: 50px;
}
