#new-clip {
  background: transparent;
  color: var(--c-primary-700);
  line-height: inherit;
  font-weight: normal;
  outline: none;
  text-transform: uppercase;
  padding: 1rem;
}

#new-clip:hover {
  box-shadow: none;
  background: var(--c-primary-100);
}

@media (min-width: 720px) {
  #queue .list-item {
    cursor: pointer;
    position: relative;
  }

  #queue .list-item--selected:after {
    border-left: 1.5rem var(--c-primary-100) solid;
    border-top: 1.75rem transparent solid;
    border-bottom: 1.75rem transparent solid;
    content: '';
    height: 0;
    left: 100%;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    width: 0;
  }
}
