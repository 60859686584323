.details .list__items {
  margin-bottom: 0;
}

.detail__body {
  display: block;
  padding: 0.75rem 0;
}

.detail:hover {
  background: inherit;
  color: inherit;
}

.detail .column {
  overflow: hidden;
  text-overflow: ellipsis;
}

.detail + .detail {
  border-top: 1px solid var(--c-gray-300);
}

.detail:last-child .detail__body {
  padding-bottom: 0;
}
