.navigation__overlay {
  background: #000000;
  height: 100vh;
  right: -100vw;
  opacity: 0;
  position: fixed;
  top: 0;
  transition: opacity 0.2s linear, right 0s linear 0.2s;
  width: 100vw;
  z-index: 9;
}

.navigation--open .navigation__overlay {
  opacity: 0.6;
  transition: opacity 0.2s linear, right 0s linear 0s;
  right: 0;
}

.navigation__body {
  background: #ffffff;
  box-shadow: var(--card-1);
  display: block;
  height: 100vh;
  overflow-y: scroll;
  padding: var(--h-header) 1rem 4rem;
  position: fixed;
  right: -100vw;
  top: 0;
  transition: right 0.2s ease-in;
  width: 20rem;
  z-index: 10;
}

.navigation--open .navigation__body {
  right: 0;
}

.navigation__close {
  align-items: center;
  background: none;
  border: none;
  display: flex;
  height: var(--h-header);
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  width: 4rem;
}

.navigation__close svg {
  height: 2rem;
  width: 2rem;
}

.navigation__list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.navigation .alert {
  margin: 0.5rem 0;
  padding: 0.25rem 0.5rem;
}

.navigation__item {
  display: block;
  position: relative;
}

.navigation__item + .navigation__item {
  border-top: 1px solid var(--c-gray-200);
}

.navigation__item .navigation__link {
  display: block;
  font-size: 1.125rem;
  height: 100%;
  padding: 1rem 0;
  width: 100%;
}

.navigation__item .button {
  margin: 1rem 0;
  width: 100%;
}

@media (min-width: 720px) {
  .navigation .navigation__overlay,
  .navigation .navigation__close {
    display: none;
  }

  .navigation__body {
    box-shadow: none;
    display: block;
    height: auto;
    overflow-y: visible;
    padding: 0;
    position: static;
    width: auto;
    z-index: 5;
  }

  .navigation__list {
    align-items: center;
    display: flex;
  }

  .navigation__item .navigation__link.active:after {
    background: var(--c-primary-200);
    bottom: -0.5rem;
    content: '';
    display: block;
    height: 2px;
    left: 0;
    position: absolute;
    width: 100%;
  }

  .navigation__item + .navigation__item {
    border-top: none;
    margin-left: 1.5rem;
  }

  .navigation__item .navigation__link {
    display: inline;
    padding: 0;
  }

  .navigation__item .button {
    margin: 0;
    padding: 0.5rem 0.75rem;
  }
}
