.page {
  background: white;
  flex: 1 0 auto;
}

.page--dim {
  background: var(--c-gray-100);
}

.page--centered {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 2rem 1rem;
}

@media (min-width: 720px) {
  .page--fixed {
    flex: 1;
    overflow-y: auto;
  }
}
