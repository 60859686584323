#pricing .table {
}

#pricing .table .column {
  flex: 1 0 auto;
  width: 5rem;
}

#pricing .table .column:first-child {
  flex: 1 0 auto;
  justify-content: flex-start;
  width: 16.25rem;
  text-align: left;
}
