.header {
  --h-header: 4rem;

  box-shadow: var(--card-1);
  height: var(--h-header);
  flex: 0 0 auto;
  padding: 1rem;
  position: relative;
  z-index: 5;
}

.header__body {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-between;
}

.header__brand {
  align-items: center;
  display: flex;
  font-family: 'Fira Sans', sans-serif;
  font-size: 1.5rem;
  font-weight: 900;
  height: 100%;
}

.header__brand svg {
  border-radius: 50%;
  height: 2rem;
  margin-right: 0.5rem;
  width: 2rem;
}

.header__menu {
  align-items: center;
  background: none;
  border: none;
  display: flex;
  height: var(--h-header);
  justify-content: center;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 4rem;
}

.header__menu svg {
  height: 2rem;
  width: 2rem;
}

@media (min-width: 720px) {
  .header {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .header .header__menu {
    display: none;
  }
}
