.card {
  background: #ffffff;
  border-radius: var(--rounded);
  box-shadow: var(--card-1);
  padding: 1.5rem 1rem;
}

.card--stretch {
  width: 100%;
}

@media (min-width: 720px) {
  .card {
    padding: 2rem 1.5rem;
  }
}
