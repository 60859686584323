.section {
  max-width: var(--max-width-md);
  margin: 0 auto;
  padding: 2rem 1rem;
  width: 100%;
}

.section--small {
  max-width: var(--max-width-sm);
}

.section--large {
  max-width: var(--max-width-lg);
}

@media (min-width: 720px) {
  .section {
    padding: 3rem 1.5rem;
  }
}
