@import url(https://fonts.googleapis.com/css?family=Fira+Sans:900|Roboto:400,400i,700&display=swap);
.icon {
  background: none;
  border: none;
  display: block;
  -webkit-flex: none;
          flex: none;
  height: 1.5rem;
  opacity: 0.55;
  padding: 0;
  width: 1.5rem;
}

.icon:focus {
  outline: none;
}

.icon:not(.icon--disabled):focus {
  -webkit-transform: translateY(-1px);
          transform: translateY(-1px);
}

button.icon {
  cursor: pointer;
}

.icon img {
  display: block;
  width: 100%;
}

.icon--disabled {
  opacity: 0.26;
}

button.icon--disabled {
  cursor: default;
}

.icon--dark {
  fill: var(--c-gray-900);
  opacity: 1;
}

.icon--white {
  fill: white;
  opacity: 1;
}

.icon--spin {
  -webkit-animation: rotation 2s infinite linear;
          animation: rotation 2s infinite linear;
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}

@keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}

.list-item .icon {
  margin-left: auto;
  margin-right: auto;
}

.alert {
  -webkit-align-items: center;
          align-items: center;
  background: var(--c-gray-200);
  border-radius: var(--rounded);
  border: 1px solid var(--c-gray-500);
  display: -webkit-flex;
  display: flex;
  margin-bottom: 1rem;
  padding: 0.75rem 1rem;
}

.alert .icon {
  display: inline;
  height: 1.5rem;
  margin-right: 0.5rem;
  opacity: 1;
}

.alert--info {
  background: var(--c-primary-100);
  border-color: var(--c-primary-300);
  color: var(--c-primary-900);
}

.alert--info .icon {
  fill: var(--c-primary-900);
}

.alert--success {
  background: var(--c-success-100);
  border-color: var(--c-success-300);
  color: var(--c-success-900);
}

.alert--success .icon {
  fill: var(--c-success-900);
}

.alert--warning {
  background: var(--c-warning-100);
  border-color: var(--c-warning-300);
  color: var(--c-warning-900);
}

.alert--warning .icon {
  fill: var(--c-warning-700);
}

.alert--error {
  background: var(--c-danger-100);
  border-color: var(--c-danger-300);
  color: var(--c-danger-900);
}

.alert--error .icon {
  fill: var(--c-danger-900);
}

.button {
  background: var(--c-primary-500);
  border: none;
  border-radius: var(--rounded);
  color: white;
  cursor: pointer;
  display: block;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1;
  margin: 0;
  padding: 0.75rem 1rem;
  position: relative;
  text-align: center;
}

.button:hover {
  box-shadow: var(--card-1);
  -webkit-transform: translate(0, -1px);
          transform: translate(0, -1px);
}

.button:focus {
  outline: none;
}

.button .icon {
  height: 1rem;
  width: 1rem;
}

.button--disabled {
  cursor: default;
  outline: none;
  opacity: 0.55;
}

.button--disabled:hover {
  box-shadow: none;
  -webkit-transform: none;
          transform: none;
}

.button--loading .button__body {
  visibility: hidden;
}

.button--loading .button__overlay {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.button--secondary {
  background: transparent;
  border: 2px solid var(--c-primary-700);
  color: var(--c-primary-700);
}

.button--stretch {
  display: block;
  width: 100%;
}

.navigation .usage {
  display: none;
}

@media (min-width: 720px) {
  .navigation .usage {
    cursor: pointer;
    display: block;
    margin-right: 0.5rem;
  }

  .navigation .usage:after {
    background: var(--c-gray-200);
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    right: -0.5rem;
    top: 0;
    width: 2px;
  }

  .usage__label,
  .usage__title {
    display: block;
    font-size: 0.75rem;
    font-weight: bold;
    letter-spacing: 0.06rem;
    margin-top: 0.125rem;
    text-align: center;
    text-transform: uppercase;
    width: 3.25rem;
  }

  .usage__label {
    letter-spacing: -0.06rem;
  }

  .usage:hover .usage__title,
  .usage__label {
    display: none;
  }

  .usage:hover .usage__label {
    display: block;
  }

  .usage__value {
    font-size: 0.875rem;
    text-align: center;
  }

  .usage--high {
    color: var(--c-warning-700);
  }

  .usage--danger {
    color: var(--c-danger-700);
  }
}

.navigation__overlay {
  background: #000000;
  height: 100vh;
  right: -100vw;
  opacity: 0;
  position: fixed;
  top: 0;
  transition: opacity 0.2s linear, right 0s linear 0.2s;
  width: 100vw;
  z-index: 9;
}

.navigation--open .navigation__overlay {
  opacity: 0.6;
  transition: opacity 0.2s linear, right 0s linear 0s;
  right: 0;
}

.navigation__body {
  background: #ffffff;
  box-shadow: var(--card-1);
  display: block;
  height: 100vh;
  overflow-y: scroll;
  padding: var(--h-header) 1rem 4rem;
  position: fixed;
  right: -100vw;
  top: 0;
  transition: right 0.2s ease-in;
  width: 20rem;
  z-index: 10;
}

.navigation--open .navigation__body {
  right: 0;
}

.navigation__close {
  -webkit-align-items: center;
          align-items: center;
  background: none;
  border: none;
  display: -webkit-flex;
  display: flex;
  height: var(--h-header);
  -webkit-justify-content: center;
          justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  width: 4rem;
}

.navigation__close svg {
  height: 2rem;
  width: 2rem;
}

.navigation__list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.navigation .alert {
  margin: 0.5rem 0;
  padding: 0.25rem 0.5rem;
}

.navigation__item {
  display: block;
  position: relative;
}

.navigation__item + .navigation__item {
  border-top: 1px solid var(--c-gray-200);
}

.navigation__item .navigation__link {
  display: block;
  font-size: 1.125rem;
  height: 100%;
  padding: 1rem 0;
  width: 100%;
}

.navigation__item .button {
  margin: 1rem 0;
  width: 100%;
}

@media (min-width: 720px) {
  .navigation .navigation__overlay,
  .navigation .navigation__close {
    display: none;
  }

  .navigation__body {
    box-shadow: none;
    display: block;
    height: auto;
    overflow-y: visible;
    padding: 0;
    position: static;
    width: auto;
    z-index: 5;
  }

  .navigation__list {
    -webkit-align-items: center;
            align-items: center;
    display: -webkit-flex;
    display: flex;
  }

  .navigation__item .navigation__link.active:after {
    background: var(--c-primary-200);
    bottom: -0.5rem;
    content: '';
    display: block;
    height: 2px;
    left: 0;
    position: absolute;
    width: 100%;
  }

  .navigation__item + .navigation__item {
    border-top: none;
    margin-left: 1.5rem;
  }

  .navigation__item .navigation__link {
    display: inline;
    padding: 0;
  }

  .navigation__item .button {
    margin: 0;
    padding: 0.5rem 0.75rem;
  }
}

.card {
  background: #ffffff;
  border-radius: var(--rounded);
  box-shadow: var(--card-1);
  padding: 1.5rem 1rem;
}

.card--stretch {
  width: 100%;
}

@media (min-width: 720px) {
  .card {
    padding: 2rem 1.5rem;
  }
}

.form {
  margin-top: 2rem;
}

.field {
  border: 1px solid var(--c-gray-300);
  border-radius: var(--rounded);
  display: block;
  font-family: 'Roboto', sans-serif;
  font-size: 0.875rem;
  padding: 0.75rem;
  width: 100%;
}

.field:focus {
  border-color: transparent;
  box-shadow: 0 0 0 2px var(--c-primary-300);
  outline: none;
}

.field-set {
  border: 1px solid var(--c-gray-300);
  border-radius: var(--rounded);
  margin: 1rem 0 0;
  padding: 0.75rem;
}

.field + .field,
.field + .button,
.field + .field-set {
  margin-top: 1rem;
}

.search {
  position: relative;
}

.search .field {
  padding-left: 3.25rem;
}

.search .icon {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  height: 100%;
  -webkit-justify-content: center;
          justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 2.5rem;
}

.search .icon:after {
  border-right: 1px solid var(--c-gray-500);
  content: '';
  height: 100%;
  opacity: 1;
  position: absolute;
  right: 0;
}

.radio {
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
}

.radio__input {
  cursor: pointer;
  display: inline-block;
  opacity: 0;
  position: fixed;
}

.radio__button {
  background: var(--c-gray-300);
  border-radius: 50%;
  border: 1px solid var(--c-gray-300);
  display: inline-block;
  -webkit-flex: none;
          flex: none;
  height: 1.25rem;
  margin-right: 0.5rem;
  width: 1.25rem;
}

.radio--checked .radio__button {
  background: var(--c-primary-300);
}

.textarea {
  background: white;
  border: 1px solid var(--c-gray-300);
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  padding: 1rem;
  resize: none;
  width: 100%;
}

.textarea:focus {
  border: 2px solid var(--c-primary-300);
  outline: none;
}

.textarea--expand {
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.5rem;
}

h3 {
  font-size: 1.125rem;
}

.heading {
  font-family: 'Fira Sans', sans-serif;
  font-weight: 900;
  margin-top: 2rem;
}

.heading:first-child {
  margin-top: 0;
}

.heading--border {
  border-bottom: 1px solid var(--c-gray-300);
  padding-bottom: 0.5rem;
}

.heading--center {
  text-align: center;
}

.card .heading {
  font-size: 1.5rem;
}

@media (min-width: 720px) {
  h1 {
    font-size: 2.25rem;
  }
}

.page {
  background: white;
  -webkit-flex: 1 0 auto;
          flex: 1 0 auto;
}

.page--dim {
  background: var(--c-gray-100);
}

.page--centered {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 2rem 1rem;
}

@media (min-width: 720px) {
  .page--fixed {
    -webkit-flex: 1 1;
            flex: 1 1;
    overflow-y: auto;
  }
}

.section {
  max-width: var(--max-width-md);
  margin: 0 auto;
  padding: 2rem 1rem;
  width: 100%;
}

.section--small {
  max-width: var(--max-width-sm);
}

.section--large {
  max-width: var(--max-width-lg);
}

@media (min-width: 720px) {
  .section {
    padding: 3rem 1.5rem;
  }
}

.text {
  line-height: 1.5;
  margin-left: auto;
  margin-right: auto;
  margin: 0 auto;
  overflow: hidden;
}

.text--bold {
  font-weight: bold;
}

.text--caps {
  text-transform: uppercase;
}

.text--center {
  text-align: center;
}

.text--tiny {
  font-size: 0.75rem;
}

.text--underline {
  text-decoration: underline;
}

.text__strong {
  color: var(--c-primary-700);
  font-weight: bold;
}

.no-break {
  white-space: nowrap;
}

.list-item .text {
  margin: 0;
}

.heading + .text {
  margin-top: 1rem;
}

.text + .text {
  margin-top: 1rem;
}

.list {
  margin: 0 auto;
}

.list-header {
  border-bottom: 1px solid var(--c-gray-300);
  border-top: 1px solid var(--c-gray-300);
  color: var(--c-gray-800);
  font-size: 0.875rem;
  font-weight: bold;
  margin-top: 0.75rem;
  padding: 0.5rem 1rem;
}

.list-header + .list-header {
  border-top: none;
  margin-top: 0;
}

.list__items {
  list-style: none;
  margin: 0;
  margin: 0.75rem 0;
  padding: 0;
}

.list-header + .list__items {
  margin-top: 0.5rem;
}

.list-item {
  background: #ffffff;
  display: block;
  line-height: 1.5;
  margin-bottom: 0.125rem;
  padding: 1rem;
  -webkit-tap-highlight-color: hsla(333, 80%, 90%, 0.5);
}

.list-item--flush {
  padding: 0;
}

.list-item--clickable {
  cursor: pointer;
}

.list-item--selected,
.list-item--clickable:hover {
  background: var(--c-primary-100);
  color: var(--c-primary-900);
}

.columns {
  display: -webkit-flex;
  display: flex;
}

.columns .column {
  -webkit-flex: none;
          flex: none;
}

.columns .column--auto {
  -webkit-flex: auto;
          flex: auto;
}

.columns .column + .column {
  margin-left: 1.5rem;
}

.columns .column + .column--float {
  margin-left: auto;
}

.column--1-3 {
  width: calc(33.333% - (0.6 * 1.5rem));
}

.column--1-2 {
  width: calc(50% - (0.5 * 1.5rem));
}

.column--1-4 {
  width: calc(25% - (0.75 * 1.5rem));
}

.details .list__items {
  margin-bottom: 0;
}

.detail__body {
  display: block;
  padding: 0.75rem 0;
}

.detail:hover {
  background: inherit;
  color: inherit;
}

.detail .column {
  overflow: hidden;
  text-overflow: ellipsis;
}

.detail + .detail {
  border-top: 1px solid var(--c-gray-300);
}

.detail:last-child .detail__body {
  padding-bottom: 0;
}

.payment-method__brand {
  text-transform: capitalize;
}

.groups .card {
  margin-bottom: 1rem;
}

#send-verification {
  background: none;
  border: none;
  color: var(--c-warning-900);
  cursor: pointer;
  font-size: inherit;
  padding: 0;
  text-decoration: underline;
}

@media (min-width: 720px) {
  .groups {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }

  .group {
    width: calc(50% - (0.5 * 1rem));
  }
}

#upgrade {
  max-width: var(--max-width-sm);
}

#upgrade .card {
  width: 100%;
}

#upgrade .heading + .text {
  margin-top: 0;
}

#upgrade .button .icon {
  margin-left: auto;
  margin-right: auto;
}

#plans {
  -webkit-flex-direction: column;
          flex-direction: column;
  grid-gap: 1rem;
}

#plans .more-details {
  position: relative;
}

#plans .more-details:after {
  background: var(--c-gray-900);
  bottom: -0.25rem;
  content: ' ';
  display: block;
  height: 1px;
  left: 0;
  position: absolute;
  width: 100%;
}

#plans li {
  padding-left: 0.75rem;
  position: relative;
}

#plans li:before {
  content: '•';
  display: block;
  height: 1rem;
  left: 0;
  width: 0.75rem;
  position: absolute;
}

@media (min-width: 960px) {
  #upgrade {
    max-width: var(--max-width-lg);
  }

  #plans {
    -webkit-flex-direction: row;
            flex-direction: row;
  }

  #plans .card + .card {
    margin-top: 0;
  }
}

#clips-list .clip__language,
#clips-list .clip__voice {
  text-transform: capitalize;
}

#clips-list .clip__icon {
  -webkit-align-items: center;
          align-items: center;
  background: var(--c-primary-200);
  border-radius: 50%;
  display: -webkit-flex;
  display: flex;
  height: 2rem;
  -webkit-justify-content: center;
          justify-content: center;
  line-height: 2rem;
  width: 2rem;
}

#clips-list .clip__icon--selected {
  background: var(--c-primary-700);
}

#clips-list .clip__expansion {
  background: var(--c-primary-100);
  color: var(--c-primary-900);
  padding: 1rem;
}

@media (max-width: 719px) {
  #clips-list .list-item {
    border: 1px solid var(--c-gray-300);;
    border-radius: var(--rounded);
    margin-bottom: 0.25rem;
    overflow: hidden;
  }
}

.pro-lock {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  margin-top: 0.25rem;
  text-align: center;
}

.pro-lock .icon,
.pro-lock svg {
  height: 1rem;
  width: 1rem;
}

#file-editor {
  display: -webkit-flex;
  display: flex;
  height: 100%;
  -webkit-flex-direction: column;
          flex-direction: column;
}

#file-editor .text {
  width: 100%;
}

#file-editor .textarea {
  min-height: 10rem;
  margin: 1rem 0;
  max-height: 50rem;
  -webkit-flex: auto;
          flex: auto;
}

input[type='file'] {
  display: none;
}

input[type='file'] + label {
  background-color: var(--c-primary-500);
  border-radius: var(--rounded);
  color: white;
  cursor: pointer;
  display: inline-block;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1;
  margin: 0;
  padding: 0.75rem 1rem;
  position: relative;
  text-align: center;
}

input[type='file'] + label:hover {
  box-shadow: var(--card-1);
  -webkit-transform: translate(0, -1px);
          transform: translate(0, -1px);
}

#editor .textarea--embed:not(:focus) {
  border-left-color: transparent;
}

.info-bar {
  -webkit-align-items: center;
          align-items: center;
  background: white;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 1rem;
}

.tip {
  font-size: 0.75rem;
  margin: 0 0.5rem;
}

#import-file {
  -webkit-align-items: flex-end;
          align-items: flex-end;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.panes {
  display: -webkit-flex;
  display: flex;
  height: 100%;
  overflow-x: hidden;
}

.pane {
  display: none;
  -webkit-flex: 1 0 100%;
          flex: 1 0 100%;
  height: 100%;
  -webkit-order: 1;
          order: 1;
  width: 100%;
}

.pane--active {
  display: block;
  -webkit-order: 0;
          order: 0;
}

.pane--medium {
  max-width: var(--max-width-md);
}

@media (min-width: 720px) {
  .pane {
    display: block;
    -webkit-flex: auto;
            flex: auto;
    -webkit-order: 0;
            order: 0;
    width: 50%;
    z-index: 0;
  }

  .pane:first-child {
    box-shadow: var(--card-1);
    overflow-y: auto;
    z-index: 1;
  }
}

.link {
  background: none;
  border: none;
  color: var(--c-primary-700);
  cursor: pointer;
  font-size: inherit;
  margin: 0;
  padding: 0;
  position: relative;
  text-decoration: none;
}

.link:after {
  background: var(--c-primary-700);
  bottom: 0;
  content: '';
  display: block;
  height: 1px;
  left: 0;
  position: absolute;
  width: 100%;
}

#new-clip {
  background: transparent;
  color: var(--c-primary-700);
  line-height: inherit;
  font-weight: normal;
  outline: none;
  text-transform: uppercase;
  padding: 1rem;
}

#new-clip:hover {
  box-shadow: none;
  background: var(--c-primary-100);
}

@media (min-width: 720px) {
  #queue .list-item {
    cursor: pointer;
    position: relative;
  }

  #queue .list-item--selected:after {
    border-left: 1.5rem var(--c-primary-100) solid;
    border-top: 1.75rem transparent solid;
    border-bottom: 1.75rem transparent solid;
    content: '';
    height: 0;
    left: 100%;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
    width: 0;
  }
}

#download-actions {
  -webkit-align-items: flex-start;
          align-items: flex-start;
  margin-top: 1rem;
}

.voice {
  text-transform: capitalize;
}

.footer {
  background: var(--c-primary-900);
  color: var(--c-gray-100);
  font-size: 0.875rem;
}

.footer a {
  text-decoration: underline;
}

#home {
  background: var(--c-primary-100);
}

#home h1 {
  font-size: 2.75rem;
}

#home .heading .icon--audio {
  -webkit-tap-highlight-color: transparent;
  display: inline;
  fill: var(--c-primary-500);
  font-size: inherit;
  opacity: 1;
  vertical-align: middle;
}

#home .heading .icon--audio,
#home .heading .icon--audio svg {
  height: 1em;
  width: 1em;
}

#home h1.heading .icon--audio {
  margin-left: 0.5rem;
}

#home h2.heading .icon--audio {
  margin-left: 0.5rem;
}

#hero {
  padding-bottom: 5rem;
  padding-top: 5rem;
}

#home .button--gradient {
  background: linear-gradient(
    to right,
    var(--c-primary-600),
    var(--c-primary-500)
  );
  box-shadow: 0 4px 24px hsla(333, 60%, 50%, 0.4);
}

#home .image {
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.2);
  border-radius: var(--rounded);
  overflow: hidden;
}

#home .image img {
  display: block;
  width: 100%;
}

#home h2 {
  font-size: 2.25rem;
}

.feature + .feature {
  margin-top: 4rem;
}

.feature .text,
#conclusion .text {
  font-size: 1.125rem;
  line-height: 1.7;
}

.feature__text {
  margin-bottom: 2rem;
}

.feature__image {
  width: 100%;
}

#conclusion {
  margin-bottom: 4rem;
}

@media (min-width: 720px) {
  #home h1 {
    font-size: 4.5rem;
  }

  #home h2 {
    font-size: 3rem;
  }

  #hero {
    padding-bottom: 6rem;
    padding-top: 6rem;
  }

  #hero .text {
    font-size: 1.5rem;
    max-width: 32rem;
  }

  #features {
    padding-top: 8rem;
  }

  .feature {
    -webkit-align-items: center;
            align-items: center;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }

  .feature + .feature {
    margin-top: 8rem;
  }

  .feature__text,
  .feature__image {
    -webkit-order: 2;
            order: 2;
    width: calc(50% - (0.5 * 1.5rem));
  }

  .feature:nth-child(even) .feature__image {
    -webkit-order: 1;
            order: 1;
  }
}

.gridded {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
}

.gridded .card {
  height: 100%;
}

.grid-item--sm-1 {
  grid-column: span 2;
}

.grid-item--sm-1-2 {
  grid-column: span 1;
}

@media (min-width: 720px) {
  .gridded {
    grid-template-columns: repeat(4, 1fr);
  }

  .grid-item--md-1-4 {
    grid-column: span 1;
  }

  .grid-item--md-1-2 {
    grid-column: span 2;
    grid-row: span 2;
  }
}

#open .card .heading {
  margin-top: 1rem;
}

#open .gridded {
  margin-top: 1rem;
}

.table {
  -webkit-flex: auto;
          flex: auto;
}

.table .row {
  -webkit-align-items: stretch;
          align-items: stretch;
  display: -webkit-flex;
  display: flex;
}

.table .row--header {
  font-size: 1rem;
  font-weight: bold;
}

.table .column {
  -webkit-align-items: center;
          align-items: center;
  border-top: 1px solid var(--c-gray-300);
  border-left: 1px solid var(--c-gray-300);
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 1rem;
  text-align: center;
}

.table .row:last-child .column {
  border-bottom: 1px solid var(--c-gray-300);
}

.table .column:last-child {
  border-right: 1px solid var(--c-gray-300);
}

.table .column--hidden {
  border: none;
}

.table .row--rounded .column:nth-child(2) {
  border-radius: var(--rounded) 0 0 0;
}

.table .row--rounded .column:last-child {
  border-radius: 0 var(--rounded) 0 0;
}

.table .row--header .column {
  background: var(--c-gray-200);
}

.table .row--header .column--highlight {
  background: var(--c-primary-200);
  color: var(--c-primary-900);
}

.column--highlight + .column--highlight {
  border-left-color: var(--c-primary-300);
}

.table .row--header .column--hidden {
  background: none;
}

/* Scroll Shadows */

.table-wrapper {
  display: -webkit-flex;
  display: flex;
  margin-top: 2rem;
  overflow-x: auto;
  position: relative;
}

.table-wrapper .shadow {
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  position: -webkit-sticky;
  position: sticky;
  width: 0;
}

.table-wrapper .shadow--show::before {
  background: rgba(0, 0, 0, 0.2);
  -webkit-filter: blur(0.25rem);
          filter: blur(0.25rem);
  content: '';
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  width: 0.25rem;
}

.table-wrapper .shadow--left {
  left: 0;
}

.table-wrapper .shadow--right {
  right: 0;
}

#pricing .table {
}

#pricing .table .column {
  -webkit-flex: 1 0 auto;
          flex: 1 0 auto;
  width: 5rem;
}

#pricing .table .column:first-child {
  -webkit-flex: 1 0 auto;
          flex: 1 0 auto;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  width: 16.25rem;
  text-align: left;
}

#voices .column {
  -webkit-flex: 1 0 auto;
          flex: 1 0 auto;
}

#voices .column:nth-child(1) {
  width: 90px;
}

#voices .column:nth-child(2) {
  width: 115px;
}

#voices .column:nth-child(3) {
  width: 125px;
}

#voices .column:nth-child(4) {
  width: 100px;
}

#voices .column:nth-child(5) {
  width: 85px;
}

#voices .column:nth-child(6) {
  width: 50px;
}

.header {
  --h-header: 4rem;

  box-shadow: var(--card-1);
  height: var(--h-header);
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
  padding: 1rem;
  position: relative;
  z-index: 5;
}

.header__body {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  height: 100%;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.header__brand {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  font-family: 'Fira Sans', sans-serif;
  font-size: 1.5rem;
  font-weight: 900;
  height: 100%;
}

.header__brand svg {
  border-radius: 50%;
  height: 2rem;
  margin-right: 0.5rem;
  width: 2rem;
}

.header__menu {
  -webkit-align-items: center;
          align-items: center;
  background: none;
  border: none;
  display: -webkit-flex;
  display: flex;
  height: var(--h-header);
  -webkit-justify-content: center;
          justify-content: center;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 4rem;
}

.header__menu svg {
  height: 2rem;
  width: 2rem;
}

@media (min-width: 720px) {
  .header {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .header .header__menu {
    display: none;
  }
}

* {
  box-sizing: border-box;
}

html,
body {
  color: hsla(333, 10%, 22%, 1);
  color: var(--c-gray-900);
  font-family: 'Roboto', sans-serif;
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

:root {
  /* borders */
  --rounded: 0.25rem;
  /* box shadows */
  --card-1: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  --card-2: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  --card-3: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  --card-4: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  --card-5: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);

  /* colors */
  --c-primary-100: hsla(333, 90%, 97%, 1);
  --c-primary-200: hsla(333, 80%, 90%, 1);
  --c-primary-300: hsla(333, 75%, 80%, 1);
  --c-primary-400: hsla(333, 75%, 65%, 1);
  --c-primary-500: hsla(333, 75%, 50%, 1);
  --c-primary-600: hsla(333, 70%, 50%, 1);
  --c-primary-700: hsla(333, 60%, 45%, 1);
  --c-primary-800: hsla(333, 55%, 35%, 1);
  --c-primary-900: hsla(333, 55%, 25%, 1);

  --c-danger-100: hsla(0, 85%, 96%, 1);
  --c-danger-300: hsla(0, 70%, 75%, 1);
  --c-danger-500: hsla(0, 70%, 55%, 1);
  --c-danger-700: hsla(0, 60%, 45%, 1);
  --c-danger-900: hsla(0, 60%, 25%, 1);

  --c-warning-100: hsla(45, 100%, 96%, 1);
  --c-warning-300: hsla(45, 90%, 85%, 1);
  --c-warning-500: hsla(45, 85%, 65%, 1);
  --c-warning-700: hsla(45, 75%, 40%, 1);
  --c-warning-900: hsla(45, 65%, 20%, 1);

  --c-success-100: hsla(145, 80%, 95%, 1);
  --c-success-300: hsla(145, 70%, 80%, 1);
  --c-success-500: hsla(145, 55%, 50%, 1);
  --c-success-700: hsla(145, 60%, 35%, 1);
  --c-success-900: hsla(145, 60%, 20%, 1);

  --c-gray-100: #faf9fa;
  --c-gray-200: #f0ecee;
  --c-gray-300: #e6dfe2;
  --c-gray-400: #dad0d5;
  --c-gray-500: #c0aeb6;
  --c-gray-600: #b09aa4;
  --c-gray-700: #9d828e;
  --c-gray-800: #806471;
  --c-gray-900: hsla(333, 10%, 22%, 1);

  /* max width */
  --max-width-sm: 31rem;
  --max-width-md: 45.75rem;
  --max-width-lg: 60rem;
}

#root {
  height: 100%;
}

.app {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 100%;
}

