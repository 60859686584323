.alert {
  align-items: center;
  background: var(--c-gray-200);
  border-radius: var(--rounded);
  border: 1px solid var(--c-gray-500);
  display: flex;
  margin-bottom: 1rem;
  padding: 0.75rem 1rem;
}

.alert .icon {
  display: inline;
  height: 1.5rem;
  margin-right: 0.5rem;
  opacity: 1;
}

.alert--info {
  background: var(--c-primary-100);
  border-color: var(--c-primary-300);
  color: var(--c-primary-900);
}

.alert--info .icon {
  fill: var(--c-primary-900);
}

.alert--success {
  background: var(--c-success-100);
  border-color: var(--c-success-300);
  color: var(--c-success-900);
}

.alert--success .icon {
  fill: var(--c-success-900);
}

.alert--warning {
  background: var(--c-warning-100);
  border-color: var(--c-warning-300);
  color: var(--c-warning-900);
}

.alert--warning .icon {
  fill: var(--c-warning-700);
}

.alert--error {
  background: var(--c-danger-100);
  border-color: var(--c-danger-300);
  color: var(--c-danger-900);
}

.alert--error .icon {
  fill: var(--c-danger-900);
}
