h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.5rem;
}

h3 {
  font-size: 1.125rem;
}

.heading {
  font-family: 'Fira Sans', sans-serif;
  font-weight: 900;
  margin-top: 2rem;
}

.heading:first-child {
  margin-top: 0;
}

.heading--border {
  border-bottom: 1px solid var(--c-gray-300);
  padding-bottom: 0.5rem;
}

.heading--center {
  text-align: center;
}

.card .heading {
  font-size: 1.5rem;
}

@media (min-width: 720px) {
  h1 {
    font-size: 2.25rem;
  }
}
