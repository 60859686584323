.list {
  margin: 0 auto;
}

.list-header {
  border-bottom: 1px solid var(--c-gray-300);
  border-top: 1px solid var(--c-gray-300);
  color: var(--c-gray-800);
  font-size: 0.875rem;
  font-weight: bold;
  margin-top: 0.75rem;
  padding: 0.5rem 1rem;
}

.list-header + .list-header {
  border-top: none;
  margin-top: 0;
}

.list__items {
  list-style: none;
  margin: 0;
  margin: 0.75rem 0;
  padding: 0;
}

.list-header + .list__items {
  margin-top: 0.5rem;
}

.list-item {
  background: #ffffff;
  display: block;
  line-height: 1.5;
  margin-bottom: 0.125rem;
  padding: 1rem;
  -webkit-tap-highlight-color: hsla(333, 80%, 90%, 0.5);
}

.list-item--flush {
  padding: 0;
}

.list-item--clickable {
  cursor: pointer;
}

.list-item--selected,
.list-item--clickable:hover {
  background: var(--c-primary-100);
  color: var(--c-primary-900);
}
