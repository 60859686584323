#file-editor {
  display: flex;
  height: 100%;
  flex-direction: column;
}

#file-editor .text {
  width: 100%;
}

#file-editor .textarea {
  min-height: 10rem;
  margin: 1rem 0;
  max-height: 50rem;
  flex: auto;
}

input[type='file'] {
  display: none;
}

input[type='file'] + label {
  background-color: var(--c-primary-500);
  border-radius: var(--rounded);
  color: white;
  cursor: pointer;
  display: inline-block;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1;
  margin: 0;
  padding: 0.75rem 1rem;
  position: relative;
  text-align: center;
}

input[type='file'] + label:hover {
  box-shadow: var(--card-1);
  transform: translate(0, -1px);
}
