.navigation .usage {
  display: none;
}

@media (min-width: 720px) {
  .navigation .usage {
    cursor: pointer;
    display: block;
    margin-right: 0.5rem;
  }

  .navigation .usage:after {
    background: var(--c-gray-200);
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    right: -0.5rem;
    top: 0;
    width: 2px;
  }

  .usage__label,
  .usage__title {
    display: block;
    font-size: 0.75rem;
    font-weight: bold;
    letter-spacing: 0.06rem;
    margin-top: 0.125rem;
    text-align: center;
    text-transform: uppercase;
    width: 3.25rem;
  }

  .usage__label {
    letter-spacing: -0.06rem;
  }

  .usage:hover .usage__title,
  .usage__label {
    display: none;
  }

  .usage:hover .usage__label {
    display: block;
  }

  .usage__value {
    font-size: 0.875rem;
    text-align: center;
  }

  .usage--high {
    color: var(--c-warning-700);
  }

  .usage--danger {
    color: var(--c-danger-700);
  }
}
