#home {
  background: var(--c-primary-100);
}

#home h1 {
  font-size: 2.75rem;
}

#home .heading .icon--audio {
  -webkit-tap-highlight-color: transparent;
  display: inline;
  fill: var(--c-primary-500);
  font-size: inherit;
  opacity: 1;
  vertical-align: middle;
}

#home .heading .icon--audio,
#home .heading .icon--audio svg {
  height: 1em;
  width: 1em;
}

#home h1.heading .icon--audio {
  margin-left: 0.5rem;
}

#home h2.heading .icon--audio {
  margin-left: 0.5rem;
}

#hero {
  padding-bottom: 5rem;
  padding-top: 5rem;
}

#home .button--gradient {
  background: linear-gradient(
    to right,
    var(--c-primary-600),
    var(--c-primary-500)
  );
  box-shadow: 0 4px 24px hsla(333, 60%, 50%, 0.4);
}

#home .image {
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.2);
  border-radius: var(--rounded);
  overflow: hidden;
}

#home .image img {
  display: block;
  width: 100%;
}

#home h2 {
  font-size: 2.25rem;
}

.feature + .feature {
  margin-top: 4rem;
}

.feature .text,
#conclusion .text {
  font-size: 1.125rem;
  line-height: 1.7;
}

.feature__text {
  margin-bottom: 2rem;
}

.feature__image {
  width: 100%;
}

#conclusion {
  margin-bottom: 4rem;
}

@media (min-width: 720px) {
  #home h1 {
    font-size: 4.5rem;
  }

  #home h2 {
    font-size: 3rem;
  }

  #hero {
    padding-bottom: 6rem;
    padding-top: 6rem;
  }

  #hero .text {
    font-size: 1.5rem;
    max-width: 32rem;
  }

  #features {
    padding-top: 8rem;
  }

  .feature {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  .feature + .feature {
    margin-top: 8rem;
  }

  .feature__text,
  .feature__image {
    order: 2;
    width: calc(50% - (0.5 * 1.5rem));
  }

  .feature:nth-child(even) .feature__image {
    order: 1;
  }
}
