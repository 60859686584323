.button {
  background: var(--c-primary-500);
  border: none;
  border-radius: var(--rounded);
  color: white;
  cursor: pointer;
  display: block;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1;
  margin: 0;
  padding: 0.75rem 1rem;
  position: relative;
  text-align: center;
}

.button:hover {
  box-shadow: var(--card-1);
  transform: translate(0, -1px);
}

.button:focus {
  outline: none;
}

.button .icon {
  height: 1rem;
  width: 1rem;
}

.button--disabled {
  cursor: default;
  outline: none;
  opacity: 0.55;
}

.button--disabled:hover {
  box-shadow: none;
  transform: none;
}

.button--loading .button__body {
  visibility: hidden;
}

.button--loading .button__overlay {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.button--secondary {
  background: transparent;
  border: 2px solid var(--c-primary-700);
  color: var(--c-primary-700);
}

.button--stretch {
  display: block;
  width: 100%;
}
