.text {
  line-height: 1.5;
  margin-left: auto;
  margin-right: auto;
  margin: 0 auto;
  overflow: hidden;
}

.text--bold {
  font-weight: bold;
}

.text--caps {
  text-transform: uppercase;
}

.text--center {
  text-align: center;
}

.text--tiny {
  font-size: 0.75rem;
}

.text--underline {
  text-decoration: underline;
}

.text__strong {
  color: var(--c-primary-700);
  font-weight: bold;
}

.no-break {
  white-space: nowrap;
}

.list-item .text {
  margin: 0;
}

.heading + .text {
  margin-top: 1rem;
}

.text + .text {
  margin-top: 1rem;
}
