.panes {
  display: flex;
  height: 100%;
  overflow-x: hidden;
}

.pane {
  display: none;
  flex: 1 0 100%;
  height: 100%;
  order: 1;
  width: 100%;
}

.pane--active {
  display: block;
  order: 0;
}

.pane--medium {
  max-width: var(--max-width-md);
}

@media (min-width: 720px) {
  .pane {
    display: block;
    flex: auto;
    order: 0;
    width: 50%;
    z-index: 0;
  }

  .pane:first-child {
    box-shadow: var(--card-1);
    overflow-y: auto;
    z-index: 1;
  }
}
