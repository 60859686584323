#upgrade {
  max-width: var(--max-width-sm);
}

#upgrade .card {
  width: 100%;
}

#upgrade .heading + .text {
  margin-top: 0;
}

#upgrade .button .icon {
  margin-left: auto;
  margin-right: auto;
}

#plans {
  flex-direction: column;
  grid-gap: 1rem;
}

#plans .more-details {
  position: relative;
}

#plans .more-details:after {
  background: var(--c-gray-900);
  bottom: -0.25rem;
  content: ' ';
  display: block;
  height: 1px;
  left: 0;
  position: absolute;
  width: 100%;
}

#plans li {
  padding-left: 0.75rem;
  position: relative;
}

#plans li:before {
  content: '•';
  display: block;
  height: 1rem;
  left: 0;
  width: 0.75rem;
  position: absolute;
}

@media (min-width: 960px) {
  #upgrade {
    max-width: var(--max-width-lg);
  }

  #plans {
    flex-direction: row;
  }

  #plans .card + .card {
    margin-top: 0;
  }
}
