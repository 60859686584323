.form {
  margin-top: 2rem;
}

.field {
  border: 1px solid var(--c-gray-300);
  border-radius: var(--rounded);
  display: block;
  font-family: 'Roboto', sans-serif;
  font-size: 0.875rem;
  padding: 0.75rem;
  width: 100%;
}

.field:focus {
  border-color: transparent;
  box-shadow: 0 0 0 2px var(--c-primary-300);
  outline: none;
}

.field-set {
  border: 1px solid var(--c-gray-300);
  border-radius: var(--rounded);
  margin: 1rem 0 0;
  padding: 0.75rem;
}

.field + .field,
.field + .button,
.field + .field-set {
  margin-top: 1rem;
}

.search {
  position: relative;
}

.search .field {
  padding-left: 3.25rem;
}

.search .icon {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 2.5rem;
}

.search .icon:after {
  border-right: 1px solid var(--c-gray-500);
  content: '';
  height: 100%;
  opacity: 1;
  position: absolute;
  right: 0;
}

.radio {
  align-items: center;
  cursor: pointer;
  display: flex;
}

.radio__input {
  cursor: pointer;
  display: inline-block;
  opacity: 0;
  position: fixed;
}

.radio__button {
  background: var(--c-gray-300);
  border-radius: 50%;
  border: 1px solid var(--c-gray-300);
  display: inline-block;
  flex: none;
  height: 1.25rem;
  margin-right: 0.5rem;
  width: 1.25rem;
}

.radio--checked .radio__button {
  background: var(--c-primary-300);
}

.textarea {
  background: white;
  border: 1px solid var(--c-gray-300);
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  padding: 1rem;
  resize: none;
  width: 100%;
}

.textarea:focus {
  border: 2px solid var(--c-primary-300);
  outline: none;
}

.textarea--expand {
  height: 100%;
}
