.info-bar {
  align-items: center;
  background: white;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}

.tip {
  font-size: 0.75rem;
  margin: 0 0.5rem;
}

#import-file {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
}
