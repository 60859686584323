.pro-lock {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 0.25rem;
  text-align: center;
}

.pro-lock .icon,
.pro-lock svg {
  height: 1rem;
  width: 1rem;
}
