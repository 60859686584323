.groups .card {
  margin-bottom: 1rem;
}

#send-verification {
  background: none;
  border: none;
  color: var(--c-warning-900);
  cursor: pointer;
  font-size: inherit;
  padding: 0;
  text-decoration: underline;
}

@media (min-width: 720px) {
  .groups {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .group {
    width: calc(50% - (0.5 * 1rem));
  }
}
