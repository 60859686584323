.table {
  flex: auto;
}

.table .row {
  align-items: stretch;
  display: flex;
}

.table .row--header {
  font-size: 1rem;
  font-weight: bold;
}

.table .column {
  align-items: center;
  border-top: 1px solid var(--c-gray-300);
  border-left: 1px solid var(--c-gray-300);
  display: flex;
  justify-content: center;
  padding: 1rem;
  text-align: center;
}

.table .row:last-child .column {
  border-bottom: 1px solid var(--c-gray-300);
}

.table .column:last-child {
  border-right: 1px solid var(--c-gray-300);
}

.table .column--hidden {
  border: none;
}

.table .row--rounded .column:nth-child(2) {
  border-radius: var(--rounded) 0 0 0;
}

.table .row--rounded .column:last-child {
  border-radius: 0 var(--rounded) 0 0;
}

.table .row--header .column {
  background: var(--c-gray-200);
}

.table .row--header .column--highlight {
  background: var(--c-primary-200);
  color: var(--c-primary-900);
}

.column--highlight + .column--highlight {
  border-left-color: var(--c-primary-300);
}

.table .row--header .column--hidden {
  background: none;
}

/* Scroll Shadows */

.table-wrapper {
  display: flex;
  margin-top: 2rem;
  overflow-x: auto;
  position: relative;
}

.table-wrapper .shadow {
  flex-shrink: 0;
  position: sticky;
  width: 0;
}

.table-wrapper .shadow--show::before {
  background: rgba(0, 0, 0, 0.2);
  filter: blur(0.25rem);
  content: '';
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  width: 0.25rem;
}

.table-wrapper .shadow--left {
  left: 0;
}

.table-wrapper .shadow--right {
  right: 0;
}
