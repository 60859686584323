.columns {
  display: flex;
}

.columns .column {
  flex: none;
}

.columns .column--auto {
  flex: auto;
}

.columns .column + .column {
  margin-left: 1.5rem;
}

.columns .column + .column--float {
  margin-left: auto;
}

.column--1-3 {
  width: calc(33.333% - (0.6 * 1.5rem));
}

.column--1-2 {
  width: calc(50% - (0.5 * 1.5rem));
}

.column--1-4 {
  width: calc(25% - (0.75 * 1.5rem));
}
