#clips-list .clip__language,
#clips-list .clip__voice {
  text-transform: capitalize;
}

#clips-list .clip__icon {
  align-items: center;
  background: var(--c-primary-200);
  border-radius: 50%;
  display: flex;
  height: 2rem;
  justify-content: center;
  line-height: 2rem;
  width: 2rem;
}

#clips-list .clip__icon--selected {
  background: var(--c-primary-700);
}

#clips-list .clip__expansion {
  background: var(--c-primary-100);
  color: var(--c-primary-900);
  padding: 1rem;
}

@media (max-width: 719px) {
  #clips-list .list-item {
    border: 1px solid var(--c-gray-300);;
    border-radius: var(--rounded);
    margin-bottom: 0.25rem;
    overflow: hidden;
  }
}
