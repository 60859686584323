.link {
  background: none;
  border: none;
  color: var(--c-primary-700);
  cursor: pointer;
  font-size: inherit;
  margin: 0;
  padding: 0;
  position: relative;
  text-decoration: none;
}

.link:after {
  background: var(--c-primary-700);
  bottom: 0;
  content: '';
  display: block;
  height: 1px;
  left: 0;
  position: absolute;
  width: 100%;
}
