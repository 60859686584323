.gridded {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
}

.gridded .card {
  height: 100%;
}

.grid-item--sm-1 {
  grid-column: span 2;
}

.grid-item--sm-1-2 {
  grid-column: span 1;
}

@media (min-width: 720px) {
  .gridded {
    grid-template-columns: repeat(4, 1fr);
  }

  .grid-item--md-1-4 {
    grid-column: span 1;
  }

  .grid-item--md-1-2 {
    grid-column: span 2;
    grid-row: span 2;
  }
}
