@import url('https://fonts.googleapis.com/css?family=Fira+Sans:900|Roboto:400,400i,700&display=swap');


* {
  box-sizing: border-box;
}

html,
body {
  color: var(--c-gray-900);
  font-family: 'Roboto', sans-serif;
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

:root {
  /* borders */
  --rounded: 0.25rem;
  /* box shadows */
  --card-1: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  --card-2: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  --card-3: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  --card-4: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  --card-5: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);

  /* colors */
  --c-primary-100: hsla(333, 90%, 97%, 1);
  --c-primary-200: hsla(333, 80%, 90%, 1);
  --c-primary-300: hsla(333, 75%, 80%, 1);
  --c-primary-400: hsla(333, 75%, 65%, 1);
  --c-primary-500: hsla(333, 75%, 50%, 1);
  --c-primary-600: hsla(333, 70%, 50%, 1);
  --c-primary-700: hsla(333, 60%, 45%, 1);
  --c-primary-800: hsla(333, 55%, 35%, 1);
  --c-primary-900: hsla(333, 55%, 25%, 1);

  --c-danger-100: hsla(0, 85%, 96%, 1);
  --c-danger-300: hsla(0, 70%, 75%, 1);
  --c-danger-500: hsla(0, 70%, 55%, 1);
  --c-danger-700: hsla(0, 60%, 45%, 1);
  --c-danger-900: hsla(0, 60%, 25%, 1);

  --c-warning-100: hsla(45, 100%, 96%, 1);
  --c-warning-300: hsla(45, 90%, 85%, 1);
  --c-warning-500: hsla(45, 85%, 65%, 1);
  --c-warning-700: hsla(45, 75%, 40%, 1);
  --c-warning-900: hsla(45, 65%, 20%, 1);

  --c-success-100: hsla(145, 80%, 95%, 1);
  --c-success-300: hsla(145, 70%, 80%, 1);
  --c-success-500: hsla(145, 55%, 50%, 1);
  --c-success-700: hsla(145, 60%, 35%, 1);
  --c-success-900: hsla(145, 60%, 20%, 1);

  --c-gray-100: #faf9fa;
  --c-gray-200: #f0ecee;
  --c-gray-300: #e6dfe2;
  --c-gray-400: #dad0d5;
  --c-gray-500: #c0aeb6;
  --c-gray-600: #b09aa4;
  --c-gray-700: #9d828e;
  --c-gray-800: #806471;
  --c-gray-900: hsla(333, 10%, 22%, 1);

  /* max width */
  --max-width-sm: 31rem;
  --max-width-md: 45.75rem;
  --max-width-lg: 60rem;
}

#root {
  height: 100%;
}

.app {
  display: flex;
  flex-direction: column;
  height: 100%;
}
